<template>
  <section>

    <!-- header row -->
    <b-row class="content-header">
      <b-col cols="12">
        <div class="breadcrumb-wrapper">
          <b-breadcrumb>
            <b-breadcrumb-item :to="{ name: 'dashboard' }">
              <feather-icon
                icon="HomeIcon"
                size="16"
                class="align-text-top"
              /> Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{ name: 'view-mycompany' }"
              :active="true"
            >
              {{ resourceName }}
            </b-breadcrumb-item>
            <!-- <b-breadcrumb-item :to="{ name: 'view-company', params: { id: resourceId } }">
              {{ resourceId }}
            </b-breadcrumb-item> -->
          </b-breadcrumb>
        </div>
      </b-col>

      <b-col
        v-if="resource.id"
        md="12"
        class="text-right"
      >
        <b-button
          variant="primary"
          :to="{ name: 'edit-mycompany' }"
        >
          <feather-icon
            icon="EditIcon"
            size="16"
            class="align-text-top"
          /> Edit {{ resourceName }}
        </b-button>
        <!-- <b-button
          class="ml-1"
          variant="primary"
          :to="{name: 'browse-companies'}"
        >
          <feather-icon
            icon="ListIcon"
            size="16"
            class="align-text-top"
          />  Manage {{ resourceNamePlural }}
        </b-button> -->
      </b-col>
    </b-row>

    <!-- no company loaded -->
    <b-card
      v-if="!resource.id"
      class="mt-2"
    >
      <b-card-title class="pl-1 mb-2 border-left-primary border-left-3">
        {{ resourceName }} Details
      </b-card-title>
      <div>
        <div class="p-1 mb-1 border-bottom rounded">
          <!-- <h5 class="text-capitalize">
            Key
          </h5> -->
          <p class="m-0">
            {{ resourceName }} data not found.
          </p>
        </div>
      </div>
    </b-card>

    <!-- company details -->
    <b-card v-if="resource.length > 0">
      <b-card-title class="pl-1 mb-2 border-left-primary border-left-3">
        {{ resourceName }} Details
      </b-card-title>
      <div
        v-for="(column, index) in resourceKeyValues"
        :key="index"
      >
        <div :class="index == resourceKeyValues.length - 1 ? 'p-1' : 'p-1 mb-1 border-bottom rounded' ">
          <h5 class="text-capitalize">
            {{ column.key }}
          </h5>
          <p class="m-0">
            {{ column.value }}
          </p>
        </div>
      </div>
    </b-card>

    <!-- company users details -->
    <b-card
      v-if="companyUsers.length > 0"
      class="mt-2"
    >
      <b-card-title class="pl-1 border-left-primary border-left-3 d-inline">
        {{ resourceName }} Users
      </b-card-title>

      <!-- Temp location for Add User button until User Management screen built -->
      <b-button
        v-b-toggle.sidebar-right
        variant="primary"
        class="ml-2 d-inline"
      >
        Add User
      </b-button>

      <b-row v-if="resource.admin">
        <b-col class="mt-1">
          <b-alert
            variant="info"
            show
          >
            <div class="alert-body">
              You are a <b>company admin</b>.
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="companyUsers.length <= 0">
        <b-col>
          No users to show.
        </b-col>
      </b-row>
      <b-row v-if="companyUsers.length > 0">
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group class="mb-0">
            <label
              class="d-block text-sm-left"
              for="perPageSelect"
            >Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          sm="8"
          class="my-1"
        >
          <b-form-group class="mb-0">
            <label
              class="d-block text-sm-left"
              for="sortBySelect"
            >Sort</label>
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">
                    Select
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group class="mb-0">
            <label
              class="d-block text-sm-left"
              for="filterInput"
            >Filter</label>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12 mt-2">
          <b-table
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="companyUsers"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >

            <template #cell(role)="data">
              {{ data.item.role.name }}
            </template>

            <template #cell(joined_at)="data">
              {{ getReadableDate(data.item.company.joined_at) }}
            </template>

            <!-- <template #cell(updated_at)="data">
              {{ getReadableDate(data.item.updated_at) }}
            </template> -->
          </b-table>
        </b-col>
        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- AddUser sidebar content -->
    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      title="Add User"
      right
      backdrop
      no-close-on-backdrop
      shadow
    >
      <div class="m-2">
        <AddUser />
      </div>
      <template slot="footer">
        <div class="p-1 text-right">
          <button
            v-b-toggle.sidebar-right
            type="button"
            class="btn btn-outline-secondary d-inline"
            size="sm"
          >
            Close Sidebar
          </button>
        </div>
      </template>
    </b-sidebar>

  </section>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BCard, BCardTitle, BButton,
  BTable, BFormGroup, BFormSelect, BPagination,
  BInputGroup, BFormInput, BInputGroupAppend, VBToggle, BSidebar, BAlert,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import AddUser from './AddUser.vue'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BButton,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BSidebar,
    BAlert,
    AddUser,
  },
  directives: {
    'b-toggle': VBToggle,
  },

  data() {
    return {
      resourceName: 'Company',
      resourceNamePlural: 'Companies',
      resource: {},
      perPage: 75,
      pageOptions: [5, 10, 15, 30, 45, 75],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'role', label: 'Role', sortable: true },
        // { key: 'first_name', label: 'First Name', sortable: true },
        // { key: 'last_name', label: 'Last Name', sortable: true },
        { key: 'full_name', label: 'Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'joined_at', label: 'Date Joined', sortable: true },
        // { key: 'updated_at', label: 'Updated At', sortable: true },
        // { key: 'actions', label: 'Actions' },
      ],
      companyUsers: [],
      dataLoadedCount: 0,
      userData: JSON.parse(localStorage.getItem('userData')),
      adminAccess: false,
    }
  },
  computed: {
    resourceId() {
      return this.$route.params.id
    },
    resourceKeyValues() {
      const keyValues = Object.keys(this.resource).map(key => {
        const dateFields = [
          'joined_at', 'updated_at',
        ]
        const value = dateFields.indexOf(key) === -1 ? this.resource[key] : this.getReadableDate(this.resource[key])
        return {
          key: key.split('_').join(' '),
          value,
        }
      })
      return keyValues
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    if (this.userData.role.id === 1) {
      // set admin access if root user
      this.adminAccess = true
    }

    // fetch company users
    useJwt.getMyCompanyUsers(this.$route.params.id)
      .then(response => {
        const companyUsers = response.data.data
        this.companyUsers = companyUsers
        // Set the initial number of items
        this.totalRows = companyUsers.length
        // indicate that data fetch was successful
        this.dataLoadedCount += 1
      })
      .catch(error => {
        console.log(error)
      })

    // fetch company
    useJwt.getMyCompany()
      .then(response => {
        console.log('getMyCompany', response.data.data)
        const resourceData = response.data.data
        this.resource = resourceData
        // indicate that data fetch was successful
        this.dataLoadedCount += 1
      })
      .catch(error => {
        if (error.response.status === 403) { // forbidden
          // this.$router.push({ name: 'error-404' })
        } else if (error.response.status === 404) { // not found
          // this.$router.push({ name: 'error-404' })
        }
      })
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getReadableDate(dateStr) {
      const date = new Date(dateStr)
      return `${date.toLocaleDateString([], { dateStyle: 'long' })} ${date.toLocaleTimeString([], { timeStyle: 'short' })}`
    },
  },
}
</script>

<style lang="scss">

</style>
